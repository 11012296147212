import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken
} from "./candy-machine";
import { programs } from "@metaplex/js";
import { Metadata } from "@metaplex/js/lib/programs/metadata";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [newMint, setNewMint] = useState<PublicKey>();
  const [newImage, setNewImage] = useState<string>();
  const [newNft, setNewNft] = useState<Metadata>();
  const [nftLoading, setNftLoading] = useState(false);
  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const stseed = new Date(props.startDate * 1000);
  const [startDate, setStartDate] = useState<Date>(stseed);
  console.log(startDate, new Date())

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  useEffect(() => {
    (async () => {
      if (props.connection && newMint) {
        setNftLoading(true);
        const pda = await programs.metadata.Metadata.getPDA(newMint);
        const meta = await programs.metadata.Metadata.load(props.connection, pda);
        setNewNft(meta);
        setNftLoading(false);
        const json = await fetch(meta.data.data.uri).then(i => i.json());
        setNewImage(json.image)
        console.log(meta);
      }
    })();
    return () => {
    };
  }, [newMint, props.connection]);

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );
      setIsActive(false)
      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const [mintTxId, mint] = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        setNewMint(mint);

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );


        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main>
      {!wallet && <h1 className="h1-home">
        <h1 className="h1-hand">Give yourself an OnlyHand</h1>
      </h1>}
      <h1 className="h1-home">
        <h1 className="h1-hand">
          {wallet && (
            <p>Price: 1.37 SOL</p>
          )}
        </h1>
        {wallet && <p>Your Current Balance: {(balance || 0).toLocaleString()} SOL</p>}
      </h1>
      {wallet && <p>Sweet, You can get {Math.floor((balance || 0) / 1.37)} OnlyHands</p>}

      <br />
      <h3 className="color">{wallet && <p>Total in Collection: 3337</p>}</h3>
      <h3 className="color">{wallet && <p>Total Airdropped: 60</p>}</h3>
      <h3 className="color">{wallet && <p>Total Reserved: 60</p>}</h3>
      <h3 className="color">{wallet && <p>Total Available: {itemsAvailable}</p>}</h3>
      <h3 className="h1-hand">{wallet && <p>Remaining: {itemsRemaining}</p>}</h3>
      <MintContainer>
        {!wallet ? (
          <div>
            <ConnectButton>Connect Wallet</ConnectButton>
            <br />
            {!isActive && <Countdown
              date={stseed}
              onMount={({ completed }) => completed && setIsActive(true)}
              onComplete={() => setIsActive(true)}
              renderer={renderCounter}
            />}
          </div>
        ) : (
          <MintButton
            className={"orange-button"}
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
          >
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                "Mint Now"
              )
            ) : (
              startDate && <Countdown
                date={startDate}
                onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            )}
          </MintButton>
        )}
      </MintContainer>
      <div>
        {nftLoading && <CircularProgress />}
        {newNft &&
          <div role="listitem" className="w-dyn-item">

            <img src={newImage} alt="" />

            <div className="product-card-pricing"></div><h3 className="link-product">{newNft && newNft.data.data.name}</h3><p>Congratulations, this is your OnlyHand</p>
            <div className="underline-wrapper"><div className="underline"></div></div>

          </div>
        }
      </div>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main >
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText className={"h1-hand"}>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
